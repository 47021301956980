.NotLoggedInHomePageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 0;
  flex-grow: 1;
}

.NotLoggedInHomePageContainer > div {
  align-self: center;
  width: 55%;
  display: flex;
  flex-direction: column;
}

.NotLoggedInHomePageContainer .LinksContainer {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
}

.NotLoggedInHomePageContainer .LinksContainer span {
  font-style: italic;
  font-size: 0.9rem;
  text-align: right;
}

.NotLoggedInHomePageContainer .LinksContainer .NewMember {
  margin-bottom: 5px;
}